import { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Loading from 'components/Loading';
import ActivatedPlan from './components/ActivatedPlan';


export default function PaymentSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1 flex flex-col">
        <header className="mt-6 mb-8 flex justify-center">
          <img src="/images/logo.png" alt="KEX Karaoke logo" />
        </header>
        <div className="flex-1 flex flex-col justify-center">
          <Suspense fallback={<Loading size="xl" />}>
            <ActivatedPlan />
          </Suspense>
        </div>
      </div>
      <div>
        <div className="mb-4">
          <Button
            onClick={() => navigate('/my-subscriptions')}
          >
            {t('payment_success.text2')}
          </Button>
        </div>
        <div>
          <Button
            onClick={() => {
              window.location.href = 'aex://main?payment_success=true';
            }}
            background="#56CCF2"
            color="#FEFEFE"
          >
            {t('payment_success.text3')}
          </Button>
        </div>
      </div>
    </div>
  )
}
