import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import Button from 'components/Button';
import SettingButton from 'components/SettingButton';
import { useDevice } from 'hooks/useDevice';
import { AddDevice } from './components/AddDevice';

export default function SerialNumber() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { device } = useDevice();

  return (
    <div className="max-w-xl min-h-screen mx-auto px-6 py-12 flex flex-col">
      <div className="flex-1 flex flex-col">
        <div className="flex justify-end">
          <SettingButton />
        </div>
        <div className="mt-6 flex-1 flex flex-col justify-center">
          <div className="mb-8">
            <div className="flex justify-center mb-10">
              <img src="/images/logo.png" alt="KEX Karaoke logo" />
            </div>
            {
              !!device && (
                <div className="text-center text-xl font-bold">
                  <div className="mr-2">{t('serial_number.text1')}</div>
                  <span>{device}</span>
                </div>
              )
            }
            {
              !device && (
                <div className="text-center text-xl font-bold">
                  <div className="mr-2">
                    <Trans key="serial_number.text7">
                      No valid device found, please scan the qr code on your TV to connect ISEE. (<a href="aex://main">Click Here</a>)
                    </Trans>
                  </div>
                </div>
              )
            }
          </div>
          <div className="mb-4">
            <Button
              onClick={() => {
                if (!device) {
                  return;
                }

                navigate('/subscription')
              }}
              background={ !!device ? '#56CCF2' : '#CCCCCC' }
              color="#FEFEFE"
            >
              {t('serial_number.text2')}
            </Button>
          </div>
          <AddDevice />
          <div className="text-center">
            <p>{t('serial_number.text4')}</p>
            <p className="text-[#B0B0B3]">{t('serial_number.text5')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
